<template>
    <div>
        <v-radio-group v-model="status">
            <v-radio :label="value.data.trueLabel" :value="true" />
            <v-radio :label="value.data.falseLabel" :value="false" />
        </v-radio-group>
        <div class="text-right">
            <v-btn class="mr-2" text @click="cancel">Cancelar</v-btn>
            <v-btn color="primary" text @click="ok">Aplicar</v-btn>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BooleanForm',
        props: {
            value: null,
            opened: false,
        },
        watch: {
            opened(value) {
                if (value) {
                    this.status = this.value.data.status;
                }
            },
        },
        data() {
            return {
                status: true,
            };
        },
        methods: {
            ok() {
                const response = this._.cloneDeep(this.value);
                response.data.status = this.status;
                this.$emit('input', response);
                this.close();
            },
            cancel() {
                this.$emit('canceled');
                this.close();
            },
            close() {
                this.$emit('update:opened', false);
            },
        },
    };
</script>

<style scoped>

</style>