import gql from 'graphql-tag';

export class AgenciaQueries {
    public static findAgencias = gql`query FindAgencias($arg: String!) {
        agencias: findAgencias(arg: $arg){
            id
            codigo
            nome
            endereco
            numero
            cidade{
                nome
                estado{
                    sigla
                }
            }
        }
    }`;

    public static update = gql`mutation ($id:Int!, $agencia:AgenciaInput!){
        updateAgencia(id: $id, data: $agencia){
            id
            endereco
            numero
            complemento
            cep
            cnpj
            bairro
            centralNf
            cidade{
                id
                nome
                estado{
                    sigla
                }
            }
        }
    }`;
}
