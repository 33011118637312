<template>
    <div>
        <v-text-field class="search-input" background-color="white"
                      placeholder="Buscar..." hide-details
                      outlined dense clearable
                      v-model="searchValue"
                      @keydown.enter="onInputValueChanged"
                      @click:clear="clearInputValue">
            <template v-slot:prepend-inner>
                <v-overflow-btn v-model="searchCriteria" @change="searchValue = null"
                                class="search-menu ma-0 mr-3" hide-details
                                :items="criteriaItems" item-text="label" item-value="name"
                                style="min-width: 150px">
                </v-overflow-btn>
            </template>
            <template v-slot:append>
                <v-btn text icon @click="expand = !expand">
                    <v-icon>mdi-filter-menu</v-icon>
                </v-btn>
            </template>
        </v-text-field>
        <v-expand-transition>
            <v-card v-show="expand" tile outlined height="100" style="border-color: #c2c2c2;margin-top: -1px;">
                <v-card-text class="ma-0 pa-0">
                    <div>
                        <v-btn class="ma-2 mb-0" rounded small depressed color="success" @click="newFilter">
                            <v-icon left>mdi-plus</v-icon> Filtro
                        </v-btn>
                    </div>
                    <div class="d-flex">
                        <div class="flex-grow-1 flex-wrap">
                            <template v-for="(filterItem, index) in enabledFilterItens">
                                <div class="d-inline-flex flex-column ma-1">
                                    <span class="mb-0 px-1 caption">{{filterItem.title}}</span>
                                    <v-menu v-model="filterItem.menu" bottom right :close-on-content-click="false"
                                            transition="scale-transition" origin="top left">
                                        <template v-slot:activator="{ on }">
                                            <v-chip pill v-on="on" close @click:close="removeFilter(index)">
                                                <v-avatar left v-if="filterItem.type === 'user'"  color="blue darken-1">
                                                    <usuario-avatar :usuario="filterItem.data" />
                                                </v-avatar>
<!--                                                <v-avatar left v-if="filterItem.type === 'tipo'">
                                                    <v-icon class="light-green lighten-3" v-text="getItemIcon(filterItem.data)"></v-icon>
                                                </v-avatar>-->
                                                <v-icon left v-if="filterItem.type === 'sort'">{{filterItem.data.field.ascending ? "mdi-sort-ascending" : "mdi-sort-descending"}}</v-icon>
                                                {{filterItem.data.toLabel()}}
                                            </v-chip>
                                        </template>
                                        <v-card min-width="300">
                                            <v-list>
                                                <v-list-item>
                                                    <user-form v-if="filterItem.type === 'user'"
                                                               v-model="enabledFilterItens[index]"
                                                               @input="onFilterUpdated"
                                                               :opened.sync="enabledFilterItens[index].menu" />
                                                    <tipo-form v-if="filterItem.type === 'tipo'"
                                                               v-model="enabledFilterItens[index]"
                                                               @input="onFilterUpdated"
                                                               :opened.sync="enabledFilterItens[index].menu" />
                                                    <!--<empreendimento-form v-if="filterItem.type === 'empreendimento'"
                                                               v-model="enabledFilterItens[index]"
                                                               @input="onFilterUpdated"
                                                               :opened.sync="enabledFilterItens[index].menu" />-->
                                                    <date-form style="width: 400px;" v-if="filterItem.type === 'date'"
                                                               v-model="enabledFilterItens[index]"
                                                               @input="onFilterUpdated"
                                                               :opened.sync="enabledFilterItens[index].menu" />
                                                    <boolean-form v-if="filterItem.type === 'boolean'"
                                                                  v-model="enabledFilterItens[index]"
                                                                  @input="onFilterUpdated"
                                                                  :opened.sync="enabledFilterItens[index].menu" />
                                                    <sort-form v-if="filterItem.type ==='sort'"
                                                               v-model="enabledFilterItens[index]"
                                                               @input="onFilterUpdated"
                                                               :opened.sync="enabledFilterItens[index].menu"/>
                                                </v-list-item>
                                            </v-list>
                                        </v-card>
                                    </v-menu>
                                </div>
                            </template>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-expand-transition>

        <new-filter-dialog ref="newFilterDialog" :items="getNonEnabledFilters()" @input="onFilterSelected" />
    </div>
</template>

<script>
    // @ts-ignore
    import UserForm from './UserForm';
    // @ts-ignore
    import TipoForm from './TipoForm';
    // @ts-ignore
    //import EmpreendimentoForm from './EmpreendimentoForm';
    // @ts-ignore
    import DateForm from './DateForm';
    // @ts-ignore
    import BooleanForm from './BooleanForm';
    // @ts-ignore
    import SortForm from './SortForm';
    // @ts-ignore
    import NewFilterDialog from './NewFilterDialog';
    import {UserData} from '@/models/filter/UserData';
    import {IdData} from '@/models/filter/IdData';
    import {DateData} from '@/models/filter/DateData';
    import {BooleanData} from '@/models/filter/BooleanData';
    import {SortData} from '@/models/filter/SortData';
    // @ts-ignore
    import UsuarioAvatar from '../../../../../components/UsuarioAvatar';
    import {getIconByFiscalizacaoTipo} from "@/assets/FiscaizacaoUtil";

    export default {
        name: 'FilterListFragment',
        props: {
            value: null,
        },
        watch: {
            value(newValue) {
                // @ts-ignore
                this.enabledFilterItens = this.value.itens ? this.value.itens : [];
                if (this.enabledFilterItens && this.enabledFilterItens.length > 0) {
                    this.expand = true;
                }
            },
        },
        components: {
            UsuarioAvatar,
            NewFilterDialog,
            SortForm,
            BooleanForm,
            DateForm,
            UserForm,
            TipoForm,
            //EmpreendimentoForm
        },
        data() {
            return {
                expand: false,
                searchValue: null,
                searchCriteria: null,
                enabledFilterItens: this.value.itens ? this.value.itens : [],

                criteriaItems: [
                    {label: 'Todos', name: 'todos'},
                    {label: 'Mutuário', name: 'mutuario'},
                    {label: 'Nº Solicitação', name: 'numero_solicitacao'},
                    {label: 'Operação', name: 'operacao'},
                    {label: 'Cédula', name: 'cedula'}],
                filterItems: [
                    {
                        title: 'Responsável',
                        name: 'responsavel',
                        type: 'user',
                        menu: false,
                        data: new UserData(null, null, null, null),
                    },
                    {
                        title: 'Tipo',
                        name: 'tipo',
                        type: 'tipo',
                        menu: false,
                        data: new IdData(null, null),
                    },
                    /*{
                        title: 'Empreendimento',
                        name: 'empreendimento',
                        type: 'empreendimento',
                        menu: false,
                        data: new IdData(null, null),
                    },
                    {
                        title: 'Data Fiscalização',
                        name: 'data-fiscalizacao',
                        type: 'date',
                        menu: false,
                        data: new DateData(new Date(), null, 'mensal'),
                    },
                    {
                        title: 'Data Limite',
                        name: 'data-limite',
                        type: 'date',
                        menu: false,
                        data: new DateData(new Date(), null, 'mensal'),
                    },
                    {
                        title: 'Fotos',
                        name: 'fotos',
                        type: 'boolean',
                        menu: false,
                        data: new BooleanData(true, 'Contém', 'Não Contém'),
                    },
                    {
                        title: 'Prorrogação Solicitada',
                        name: 'prorrogacao-solicitada',
                        type: 'boolean',
                        menu: false,
                        data: new BooleanData(true, 'Sim', 'Não'),
                    },
                    {
                        title: 'Prorrogadas',
                        name: 'prorrogadas',
                        type: 'boolean',
                        menu: false,
                        data: new BooleanData(true, 'Sim', 'Não'),
                    },
                    {
                        title: 'Lembretes',
                        name: 'lembretes',
                        type: 'boolean',
                        menu: false,
                        data: new BooleanData(true, 'Contém', 'Não Contém'),
                    },
                    {
                        title: 'Regular',
                        name: 'regular',
                        type: 'boolean',
                        menu: false,
                        data: new BooleanData(true, 'Sim', 'Não'),
                    },*/
                    {
                        title: 'Anexos',
                        name: 'arquivos',
                        type: 'boolean',
                        menu: false,
                        data: new BooleanData(true, 'Contém', 'Não Contém'),
                    },
                    {
                        title: 'Com Titulo de Cobrança',
                        name: 'titulo-cobranca',
                        type: 'boolean',
                        menu: false,
                        data: new BooleanData(true, 'Contém', 'Não Contém'),
                    },
                    {
                        title: 'Pagamento efetuado',
                        name: 'pagamento-efetuado',
                        type: 'boolean',
                        menu: false,
                        data: new BooleanData(true, 'Sim', 'Não'),
                    },
                    /*{
                        title: 'Agência',
                        name: 'agencia',
                        type: 'boolean',
                        menu: false,
                        data: new BooleanData(true, 'Contém', 'Não Contém'),
                    },
                    {
                        title: 'Contatos',
                        name: 'contatos',
                        type: 'boolean',
                        menu: false,
                        data: new BooleanData(true, 'Contém', 'Não Contém'),
                    },
                    {
                        title: 'Objetos',
                        name: 'objetos',
                        type: 'boolean',
                        menu: false,
                        data: new BooleanData(true, 'Contém', 'Não Contém'),
                    },
                    {
                        title: 'Garantias',
                        name: 'garantias',
                        type: 'boolean',
                        menu: false,
                        data: new BooleanData(true, 'Contém', 'Não Contém'),
                    },
                    {
                        title: 'Coordenadas',
                        name: 'coordenadas',
                        type: 'boolean',
                        menu: false,
                        data: new BooleanData(true, 'Contém', 'Não Contém'),
                    },
                    {
                        title: 'Email enviado',
                        name: 'email-enviado',
                        type: 'boolean',
                        menu: false,
                        data: new BooleanData(true, 'Sim', 'Não'),
                    },*/
                    {
                        title: 'Status',
                        name: 'status',
                        type: 'boolean',
                        menu: false,
                        data: new BooleanData(true, 'Finalizado', 'Pendente'),
                    },
                    /*{
                        title: 'Nota Fiscal',
                        name: 'nota-fiscal',
                        type: 'boolean',
                        menu: false,
                        data: new BooleanData(true, 'Emitida', 'Não Emitida'),
                    },*/
                    {
                        title: 'Ordernar',
                        name: 'ordenar',
                        type: 'sort',
                        menu: false,
                        data: new SortData('Mutuario', 'mutuario', true),
                    },
                ],
            };
        },
        methods: {
            getNonEnabledFilters() {
                return this._.orderBy(this.filterItems, [f => f.title]).filter((filterItem) => {
                    return !this.enabledFilterItens.find((enabledFilter) => filterItem.name === enabledFilter.name);
                });
            },
            newFilter() {
                this.$refs.newFilterDialog.openDialog();
            },
            onInputValueChanged(value) {
                this.$emit('input', this.getFilterObject());
            },
            clearInputValue() {
                this.searchValue = null;
                this.$emit('input', this.getFilterObject());
            },
            onFilterSelected(filterItem) {
                this.enabledFilterItens.push(filterItem);
                this.$emit('input', this.getFilterObject());
            },
            onFilterUpdated(filterItem) {
                this.$emit('input', this.getFilterObject());
            },
            removeFilter(index) {
                this.enabledFilterItens.splice(index, 1);
                this.$emit('input', this.getFilterObject());
            },
            getFilterObject() {
                return {
                    searchValue: this.searchValue,
                    searchCriteria: this.searchCriteria,
                    itens: this.enabledFilterItens,
                };
            },
            getItemIcon(tipo){
                return getIconByFiscalizacaoTipo(tipo);
            },

        },
        mounted() {
            this.searchCriteria = this.criteriaItems[0].name;
        },
    };
</script>

<style>
    .search-input .v-input__control .v-input__slot{
        padding-left: 0px !important;
        border: 0px;
    }

    .search-input .v-input__control .v-input__slot .v-input__prepend-inner{
        margin-top: 2px !important;
    }

    .search-input>.v-input__control>.v-input__slot>.v-input__append-inner>.v-input__icon{
        margin-top: 6px !important;
    }

    .search-input>.v-input__control>.v-input__slot>.v-input__append-inner{
        margin-top: 3px !important;
    }

    .search-menu{
        border-right: solid 1px rgba(0, 0, 0, 0.32);
    }

    .search-menu .v-input__control:before,
    .search-menu .v-input__slot:before{
        display: none;
    }

    .search-menu .v-input__control .v-input__slot{
        box-shadow: unset;
        /*background: unset !important;*/
    }

    .search-menu .v-input__control .v-input__slot:after{
        display: none;
    }

    .search-menu>.v-input__control>.v-input__slot>.v-select__slot>.v-input__append-inner{
        margin-top: unset !important;

    }
    /*.search-select-input .v-text-field__details{
        margin-bottom: unset;
    }*/
</style>