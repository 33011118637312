<template>
    <page title="Projetos">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
        </v-breadcrumbs>
        <div slot="menu">
            <v-btn text outlined @click="openNovoProjetoDialog">Novo Projeto</v-btn>
        </div>

        <filter-list-fragment v-model="currentFilter" @input="onFilterChanged" />

        <div class="mt-2" v-if="totalCount > 0">
            <span class="subtitle-2 font-weight-regular">{{totalCount}} resultados encontrados</span>
        </div>



        <div class="text-center" v-if="projetos">
            <v-pagination v-model="page" total-visible="8"
                          :length="Math.ceil(totalCount / offset)"
                          @input="pageChanged"></v-pagination>
        </div>

        <v-card class="mt-3">
<!--            <v-list class="projetos py-0" :three-line="$vuetify.breakpoint.smAndUp" :two-line="$vuetify.breakpoint.xsOnly" v-if="projetos && projetos.length > 0">-->
            <v-list class="projetos py-0" two-line v-if="projetos && projetos.length > 0">
                <template v-for="(projeto, index) in projetos">
                    <v-list-item :dense="$vuetify.breakpoint.xsOnly" :key="projeto.id" :to="{ name: 'projeto_view', params: { id: parseInt(projeto.id) }}" >
<!--                            <v-list-item-avatar>
                            <v-icon class="light-green lighten-3" v-text="getItemIcon(fiscalizacao.tipo)"></v-icon>
                        </v-list-item-avatar>-->
                        <v-list-item-content>
                            <v-list-item-title v-if="!$vuetify.breakpoint.xsOnly" v-html="highlight(projeto.cliente, currentFilter.searchValue)"></v-list-item-title>
                            <v-list-item-title v-else class="body-2" v-html="highlight(projeto.cliente, currentFilter.searchValue)"></v-list-item-title>
<!--                                <v-list-item-subtitle v-if="!$vuetify.breakpoint.xsOnly" v-html="'N. Solicitação: ' + highlight(fiscalizacao.numeroSolicitacao, currentFilter.searchValue) + '&emsp;Operação: ' + highlight(fiscalizacao.financiamento.operacao+(fiscalizacao.financiamento.operacaoDigito ? '-'+fiscalizacao.financiamento.operacaoDigito : ''), currentFilter.searchValue)" >
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-else class="caption" style="line-height: 10px" v-html="'N. Sol.: ' + highlight(fiscalizacao.numeroSolicitacao, currentFilter.searchValue) + '&emsp;Op.: ' + highlight(fiscalizacao.financiamento.operacao+(fiscalizacao.financiamento.operacaoDigito ? '-'+fiscalizacao.financiamento.operacaoDigito : ''), currentFilter.searchValue)" >
                            </v-list-item-subtitle>-->
                            <div>
                                <v-chip x-small label color="orange" text-color="white" class="mr-1 font-weight-bold px-2">
                                    <span>{{projeto.tipo.nome}}</span>
                                </v-chip>
<!--                                <v-tooltip bottom v-if="fiscalizacao.emailEnviadoAt">
                                    <template v-slot:activator="{ on }">
                                        <v-chip v-on="on" x-small label color="blue" text-color="white" class="mr-1 font-weight-bold px-2">
                                            EMAIL ENVIADO
                                        </v-chip>
                                    </template>
                                    <span>{{fiscalizacao.emailEnviadoAt | moment('DD MMM YYYY HH:mm [hrs]')}}</span>
                                </v-tooltip>

                                <v-chip v-if="fiscalizacao.fotos.length > 0" x-small label color="orange" text-color="white" class="mr-1 font-weight-bold px-2">
                                    <span>{{fiscalizacao.fotos.length}} {{fiscalizacao.fotos.length > 1 ? "FOTOS" : "FOTO"}}</span>
                                </v-chip>

                                <v-chip v-if="fiscalizacao.arquivos.length > 0" x-small label color="teal lighten-1" text-color="white" class="mr-1 font-weight-bold px-2">
                                    <span>{{fiscalizacao.arquivos.length}} {{fiscalizacao.arquivos.length > 1 ? "ANEXOS" : "ANEXO"}}</span>
                                </v-chip>

                                <v-chip v-if="fiscalizacao.contatos.length > 0" x-small label color="blue-grey darken-1" text-color="white" class="mr-1 font-weight-bold px-2">
                                    <span>{{fiscalizacao.contatos.length}} {{fiscalizacao.contatos.length > 1 ? "CONTATOS" : "CONTATO"}}</span>
                                </v-chip>

                                <v-chip v-if="fiscalizacao.lembretes.length > 0" x-small label color="yellow" text-color="" class="mr-1 font-weight-medium px-2">
                                    <span>{{fiscalizacao.lembretes.length}} {{fiscalizacao.lembretes.length > 1 ? "LEMBRETES" : "LEMBRETE"}}</span>
                                </v-chip>-->

                            </div>
                        </v-list-item-content>

                        <v-list-item-action v-if="!$vuetify.breakpoint.xsOnly">
                            <v-list-item-action-text >Data Solicitação: <b>{{new Date(projeto.dataSolicitacao) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                            <v-list-item-action-text v-if="projeto.dataEnvio">Data Envio: <b>{{new Date(projeto.dataEnvio) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                        </v-list-item-action>

                    </v-list-item>

                    <v-divider ></v-divider>
                </template>
            </v-list>

            <div v-else-if="loading === false && (projetos === null || projetos.length === 0)" class="text-center pa-6">
                <h1 class="display-3 mb-4">Ops!</h1>
                <span class="headline font-weight-light">Nenhum resultado encontrado!</span>
            </div>
            <v-overlay :value="loading" color="white" absolute opacity="0.8" z-index="1" />
            <v-progress-linear :active="loading" :indeterminate="loading"
                               absolute top color="secondary" />
    </v-card>

        <div class="text-center mt-3" v-if="projetos">
            <v-pagination v-model="page" total-visible="8"
                          :length="Math.ceil(totalCount / offset)"
                          @input="pageChanged"></v-pagination>
        </div>
        <novo-projeto-dialog ref="novoProjetoDialog" />
    </page>
</template>

<script>
// @ts-ignore
import page from '../../../components/Page';
import gql from "graphql-tag";
import localforage from "localforage";
// @ts-ignore
import FilterListFragment from './fragments/filter/FilterListFragment';
import {UserData} from "@/models/filter/UserData";
import {IdData} from "@/models/filter/IdData";
import {BooleanData} from "@/models/filter/BooleanData";
import {DateData} from "@/models/filter/DateData";
import {SortData} from "@/models/filter/SortData";
import NovoProjetoDialog from "@/views/servico/projeto/NovoProjetoDialog";

export default {
    name: "Index",
    components: {
        FilterListFragment,
        NovoProjetoDialog,
        page,
    },
    data() {
        return {
            offset: 10,
            page: 1,
            totalCount: 0,
            projetos: [],
            currentFilter: {},
            loading: false,
        }
    },
    watch: {
        $route(to, from) {
            if(to.query.page === undefined){
                this.$router.push({query:{page: '1'}});
                return;
            }
            this.page = parseInt(to.query.page);
            if(to.query.page === from.query.page){
                return
            }

            this.getList(this.currentFilter)
        }
    },
    mounted() {
        document.title = "Projetos - JF Assessoria Rural"
        this.loading = true;
        //this.getUsuarioInfo();
        this.loadFilter().then((filter) => {
            if(!this.$route.query.page){
                this.$router.replace({query:{page:this.page}});
                return;
            }else{
                this.page = parseInt(this.$route.query.page);
            }
            if (filter) {
                // TODO Montar o componente do filtro aqui
                this.currentFilter = filter;
                this.getList(this.currentFilter);
            } else {
                this.getList(this.currentFilter);
            }
        });
    },
    methods: {
        pageChanged(value){
            this.$router.push({query:{page:this.page}});
        },
        getList(filter) {
            this.loading = true;

            this.$apollo.query({
                query: gql`query ($filter: SearchFilterInput!, $page: Int, $offset: Int){
                    searchProjetos(filter: $filter, page: $page, offset: $offset){
                        totalCount
                        projetos:itens{
                            id
                            cliente
                            dataSolicitacao
                            dataEnvio
                            tipo{
                                nome
                            }
                        }
                    }
                }`,
                variables: {
                    filter: this.formatFilterToRequest(filter),
                    page: this.page - 1,
                    offset: this.offset
                },
            }).then((result) => {
                this.projetos = result.data.searchProjetos.projetos;
                this.totalCount = result.data.searchProjetos.totalCount;
                this.loading = false;
            });
        },
        onFilterChanged(filter) {
            this.currentFilter = filter;
            this.saveFilter(filter);
            if(this.$route.query.page == 1){
                this.getList(this.currentFilter);
                return
            }
            this.$router.replace({query:{page:1}});
        },
        formatFilterToRequest(filter) {
            const parsedFilter = this._.cloneDeep(filter);

            if (!parsedFilter.searchValue) {
                parsedFilter.searchCriteria = null;
            }

            if (filter.itens) {
                parsedFilter.itens = filter.itens.map((filterItem) => {
                    const filterItemMapped = {
                        name: filterItem.name,
                    };

                    switch (filterItem.type) {
                        case 'boolean':
                            filterItemMapped.booleanData = this._.cloneDeep(filterItem.data);
                            delete filterItemMapped.booleanData.trueLabel;
                            delete filterItemMapped.booleanData.falseLabel;
                            delete filterItemMapped.booleanData.toLabel;

                            break;
                        case 'sort':
                            filterItemMapped.sortData = this._.cloneDeep(filterItem.data);
                            filterItemMapped.sortData.fieldName = filterItemMapped.sortData.field.name;
                            delete filterItemMapped.sortData.field;
                            delete filterItemMapped.sortData.toLabel;
                            break;
                        case 'user':
                            filterItemMapped.userData = this._.cloneDeep(filterItem.data);
                            delete filterItemMapped.userData.nome;
                            delete filterItemMapped.userData.sobrenome;
                            delete filterItemMapped.userData.email;
                            delete filterItemMapped.userData.imagem;
                            delete filterItemMapped.userData.toLabel;
                            break;
                        case 'tipo':
                            filterItemMapped.idData = this._.cloneDeep(filterItem.data);
                            delete filterItemMapped.idData.nome;
                            delete filterItemMapped.idData.toLabel;
                            break;
                        /*case 'empreendimento':
                            filterItemMapped.idData = this._.cloneDeep(filterItem.data);
                            delete filterItemMapped.idData.nome;
                            delete filterItemMapped.idData.toLabel;
                            break;*/
                        case 'date':
                            filterItemMapped.dateData = this._.cloneDeep(filterItem.data);
                            delete filterItemMapped.dateData.rangeType;
                            delete filterItemMapped.dateData.toLabel;
                    }

                    return filterItemMapped;
                });
            }

            return parsedFilter;
        },
        async loadFilter() {
            const savedFilter = await localforage.getItem('projetos_filter');
            if (savedFilter) {
                savedFilter.itens.map((filterItem) => {
                    switch (filterItem.type) {
                        case 'user':
                            filterItem.data = new UserData(
                                filterItem.data.id, filterItem.data.nome, filterItem.data.sobrenome,
                                filterItem.data.imagem);
                            break;
                        /*case 'tipo':
                            filterItem.data = new IdData(filterItem.data.id, filterItem.data.nome);
                            break;
                        case 'empreendimento':
                            filterItem.data = new IdData(filterItem.data.id, filterItem.data.nome);
                            break;*/
                        case 'boolean':
                            filterItem.data = new BooleanData(
                                filterItem.data.status, filterItem.data.trueLabel, filterItem.data.falseLabel);
                            break;
                        case 'date':
                            filterItem.data = new DateData(filterItem.data.before, filterItem.data.after, filterItem.data.rangeType);
                            break;
                        case 'sort':
                            filterItem.data = new SortData(
                                filterItem.data.field.label, filterItem.data.field.name, filterItem.data.ascending);
                            break;
                    }
                    return filterItem;
                });
            }
            return savedFilter;
        },
        highlight: function(words, query){
            if(query === ''){
                return words;
            }
            return words.toString().replace(new RegExp(query, "gi"), matchedTxt => {
                return '<span class="highlight">' + matchedTxt + '</span>';
            });
        },

        saveFilter(filter) {
            const filterParsed = this._.cloneDeep(filter);
            filterParsed.searchCriteria = null;
            filterParsed.searchValue = null;

            localforage.setItem('projetos_filter', filterParsed);
        },

        openNovoProjetoDialog(){
            this.$refs.novoProjetoDialog.openDialog();
        }
    }
}
</script>

<style scoped>

</style>