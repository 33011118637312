export class IdData {
    public id: number;
    public nome: string;

    constructor(id: number, nome: string) {
        this.id = id;
        this.nome = nome;
    }

    public toLabel() {
        return this.nome;
    }
}
