<template>
    <v-img :title="usuario.nome" v-if="usuario.imagem && renderImage" :src="$env.VUE_APP_API_ENDPOINT + 'usuarios/'+usuario.id+'/image/xs'"
           :lazy-src="$env.VUE_APP_API_ENDPOINT + 'usuarios/'+usuario.id+'/image/0'">
    </v-img>
    <span v-else :title="usuario.nome" :class="'white--text ' + textClass ">{{makeAvatarText(usuario)}}</span>
</template>

<script>
    export default {
        name: 'UsuarioAvatar',
        props: {
            usuario: {
                type: Object,
                required: true,
            },
            textClass: {
                type: String,
                default: 'subtitle-1',
            },
        },
        data() {
            return {
                renderImage: true,
            };
        },
        methods: {
            makeAvatarText(usuario) {
                if (usuario.nome === undefined) {
                    return '';
                } else {
                    return usuario.nome[0].toUpperCase() + (usuario.sobrenome ? usuario.sobrenome[0].toUpperCase() : '');
                }
            },
            forceImageRerender() {
                this.renderImage = false;
                this.$nextTick(() => {
                    this.renderImage = true;
                });
            },

        },
        mounted() {
            this.$root.$on('profilePictureUpdated', this.forceImageRerender);
        },
        destroyed() {
            this.$root.$off('profilePictureUpdated', this.forceImageRerender);
        },
    };
</script>

<style scoped>

</style>