<template>
    <v-form ref="form1" style="width: 100%" v-model="form1Valid" lazy-validation>
        <v-row>
            <v-col class="pb-0">
                <v-radio-group v-model="rangeType" row  hide-details>
                    <v-radio label="Mensal" value="mensal"></v-radio>
                    <v-radio label="Personalizado" value="personalizado"></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row v-if="rangeType === 'mensal'">
            <v-col cols="6">
                <v-menu v-model="monthlyMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="monthlyFormated"
                                label="Período"
                                prepend-icon="event"
                                readonly
                                v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="month"
                                   type="month" no-title
                                   locale="pt-br"
                                   @input="monthlyMenu = false">
                    </v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row v-if="rangeType === 'personalizado'">
            <v-col cols="6">
                <v-menu v-model="afterMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field v-model="afterFormated"
                                label="A partir de"
                                prepend-icon="event" hide-details
                                readonly clearable v-on="on"
                                @click:clear="after = null">
                        </v-text-field>
                    </template>
                    <v-date-picker v-model="after"
                                   @input="afterMenu = false"
                                   locale="pt-br" no-title
                                   :max="before">
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="6">
                <v-menu v-model="beforeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="beforeFormated"
                                label="Até"
                                prepend-icon="event" hide-details
                                readonly clearable v-on="on"
                                @click:clear="before = null">
                        </v-text-field>
                    </template>
                    <v-date-picker v-model="before"
                                   @input="beforeMenu = false"
                                   locale="pt-br" no-title
                                   :min="after">
                    </v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-alert type="error" dense v-if="noDatesError">
            Selecione pelo menos uma data
        </v-alert>
        <div class="text-right">
            <v-btn class="mr-2" text @click="cancel">Cancelar</v-btn>
            <v-btn color="primary" text @click="ok">Aplicar</v-btn>
        </div>
    </v-form>
</template>

<script>
    export default {
        name: 'DateForm',
        props: {
            value: null,
            opened: false,
        },
        watch: {
            month: {
                handler(value) {
                    this.after = value + '-01';
                    this.before = value + '-' + this.$moment(value, 'YYYY-MM').daysInMonth();
                },
                immediate: true
            },
            before(value) {
                this.noDatesError = false;
            },
            after(value) {
                this.noDatesError = false;
            },
            opened(value) {
                if (value) {
                    switch (this.value.data.rangeType) {
                        case "mensal":
                            //this.month = this.$moment(this.value.data.after).format('MMMM [de] YYYY');
                            this.month = this.$moment(this.value.data.after).format('YYYY-MM');
                            break;
                        case "personalizado":
                            this.before = this.value.data.before ? this.$moment(this.value.data.before).format('YYYY-MM-DD') : null;
                            this.after = this.value.data.after ? this.$moment(this.value.data.after).format('YYYY-MM-DD') : null;
                            break
                    }
                }
            },
        },
        computed: {
            beforeFormated: {
                get() {
                    return this.before ? this.$moment(this.before).format('DD MMM YYYY') : '';
                },
                set(newValue) {
                    if (newValue) {
                        this.before = newValue;
                    }
                },
            },
            afterFormated: {
                get() {
                    return this.after ? this.$moment(this.after).format('DD MMM YYYY') : '';
                },
                set(newValue) {
                    if (newValue) {
                        this.after = newValue;
                    }
                },
            },
            monthlyFormated: {
                get() {
                    return this.month ? this.$moment(this.month).format('MMMM [de] YYYY') : '';
                },
                set(newValue) {
                    if (newValue) {
                        this.month = newValue;
                    }
                },
            },
        },
        data() {
            return {
                form1Valid: true,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
                rangeType: this.value.data.rangeType ? this.value.data.rangeType : 'mensal',
                monthlyMenu: false,
                monthly: false,
                beforeMenu: false,
                afterMenu: false,
                before: this.value.data.before ? this.$moment(this.value.data.before).format('YYYY-MM-DD') : null,
                after: this.value.data.after ? this.$moment(this.value.data.after).format('YYYY-MM-DD') : null,
                month: this.value.data.rangeType === 'mensal' ? this.$moment(this.value.data.before).format('YYYY-MM') : null,
                noDatesError: false,
            };
        },
        methods: {
            ok() {
                if (this.after === null && this.before === null) {
                    this.noDatesError = true;
                    return;
                }
                const response = this._.cloneDeep(this.value);
                response.data.before = this.before ? new Date(this.before.replace('-', '/')) : null;
                response.data.after = this.after ? new Date(this.after.replace('-', '/')) : null;
                response.data.rangeType = this.rangeType;

                this.$emit('input', response);
                this.close();
            },
            cancel() {
                this.$emit('canceled');
                this.close();
            },
            close() {
                this.$emit('update:opened', false);
            },
        },
    };
</script>

<style scoped>

</style>