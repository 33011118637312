import gql from "graphql-tag";
<template>
    <v-form ref="form1" style="width: 100%" v-model="form1Valid" lazy-validation>
        <v-autocomplete v-model="usuario" label="Responsável" placeholder=" "
                        :items="usuarios" item-text="nome"
                        auto-select-first return-object
                        hide-no-data :rules="requiredRules">
            <template v-slot:item="data">
                <v-list-item-avatar color="blue darken-1">
                    <usuario-avatar :usuario="data.item" />
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{data.item.nome}} {{data.item.sobrenome}}</v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
                    <div>
                        <v-chip v-if="!data.item.active"
                                x-small label
                                color="red darken-1" text-color="white"
                                class="font-weight-bold">
                            <span>Inativo</span>
                        </v-chip>
                    </div>
                </v-list-item-content>
            </template>
        </v-autocomplete>
        <div class="text-right">
            <v-btn class="mr-2" text @click="cancel">Cancelar</v-btn>
            <v-btn color="primary" text @click="ok">Aplicar</v-btn>
        </div>
    </v-form>
</template>

<script>
    import gql from 'graphql-tag';
    // @ts-ignore
    import UsuarioAvatar from '../../../../../components/UsuarioAvatar';

    export default {
        name: 'UserForm',
        components: {UsuarioAvatar},
        props: {
            value: null,
            opened: null,
        },
        watch: {
            opened(value) {
                if (value) {
                    this.getUsuarios();
                    this.usuario = this.value.data.id ? this.value.data : null;
                    /*this.before = this.value.before ? this.$moment(this.value.before).format("YYYY-MM-DD") : null;
                    this.after = this.value.after ? this.$moment(this.value.after).format("YYYY-MM-DD") : null;*/
                }
            },
        },
        data() {
            return {
                form1Valid: true,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
                usuario: this.value.data.id ? this.value.data : null,
                usuarios: null,
            };
        },
        methods: {
            ok() {
                if (this.$refs.form1.validate()) {
                    const response = this._.cloneDeep(this.value);
                    response.data.id = this.usuario.id;
                    response.data.nome = this.usuario.nome;
                    response.data.sobrenome = this.usuario.sobrenome;
                    response.data.email = this.usuario.email;
                    response.data.imagem = this.usuario.imagem;
                    this.$emit('input', response);
                    this.close();
                }
            },
            cancel() {
                this.$refs.form1.reset();
                this.$emit('canceled');
                this.close();
            },
            close() {
                this.$emit('update:opened', false);
            },
            getUsuarios() {
                this.$apollo.query({
                    query: gql`query {
                        usuarios(withInatives: true){
                            id
                            nome
                            sobrenome
                            active
                            email
                            imagem{
                                id
                            }
                        }
                    }`
                }).then((result) => {
                    this.usuarios = result.data.usuarios.map((usuario) => {
                        // usuario.nome = usuario.nome + " " + usuario.sobrenome;
                        return usuario;
                    });
                });
            },
        },
        mounted() {
            this.getUsuarios();
        },
    };
</script>

<style scoped>

</style>