<template>
    <v-dialog v-model="dialog" v-if="dialog" scrollable persistent max-width="600">
        <v-card>
            <v-card-title>
                <span class="headline">Novo Projeto</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form1" v-model="form1Valid" lazy-validation>
                    <!--Tipo-->
                    <v-row>
                        <v-col cols="6">
                            <v-autocomplete v-model="projeto.tipo.id"
                                            label="Tipo" placeholder=" " :items="tipos"
                                            item-text="nome" item-value="id" :rules="requiredRules">
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row >
                        <!--Cliente-->
                        <v-col>
                            <v-text-field v-model="projeto.cliente" label="Cliente" class="cliente-text-field"
                                          placeholder=" " :rules="requiredRules" required></v-text-field>
                        </v-col>
                        <!--Idêntificação-->
                        <v-col>
                            <v-text-field v-model="projeto.identificacao" label="Idêntificação (CPF/CNPJ)"
                                          maxlength="18" placeholder=" " clearable
                                          v-mask="['###.###.###-##', '##.###.###/####-##']"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <!--Agência Solicitante-->
                        <v-col>
                            <v-autocomplete v-model="projeto.agenciaSolicitante.id" label="Agência Solicitante" placeholder=" "
                                            :items="agencias" :loading="isAgenciasLoading"
                                            :search-input.sync="searchAgenciasValue"
                                            item-text="nome" item-value="id" auto-select-first
                                            hide-no-data :rules="requiredRules" eager>
                            </v-autocomplete>
                        </v-col>
                        <!--Data Solicitação-->
                        <v-col>
                            <v-text-field v-model="projeto.dataSolicitacao" label="Data Solicitação"
                                          v-mask="'##/##/####'" placeholder=" " :rules="requiredRules" required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <!--Responsável-->
                        <v-col cols="6">
                            <v-autocomplete v-model="projeto.supervisedBy" label="Responsável" placeholder=" "
                                            :items="usuarios" item-text="nome"
                                            auto-select-first return-object
                                            hide-no-data :rules="requiredRules">
                                <template v-slot:item="data">
                                    <v-list-item-avatar color="blue darken-1">
                                        <usuario-avatar :usuario="data.item" />
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>{{data.item.nome}} {{data.item.sobrenome}}</v-list-item-title>
                                        <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
                                        <div>
                                            <v-chip v-if="!data.item.active"
                                                    x-small label
                                                    color="red darken-1" text-color="white"
                                                    class="font-weight-bold">
                                                <span>Inativo</span>
                                            </v-chip>
                                        </div>
                                    </v-list-item-content>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn text color="primary" @click="save()">
                            Salvar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import gql from "graphql-tag";
import {AgenciaQueries} from "@/queries/AgenciaQueries";
// @ts-ignore
import UsuarioAvatar from '../../../components/UsuarioAvatar';

export default {
    name: "NovoProjetoDialog",
    components:{
        UsuarioAvatar
    },
    data(){
        return{
            dialog: false,
            form1Valid: true,
            requiredRules: [
                (v) => !!v || 'Campo obrigatório',
            ],
            projeto:{
                cliente: null,
                tipo:{
                    id: null
                },
                agenciaSolicitante:{
                    id: null
                },
                dataSolicitacao: null,
                identificacao: null,
                supervisedBy: null,
            },
            usuarios: [],
            tipos: [],
            agencias: [],
            isAgenciasLoading: false,
            searchAgenciasValue: null,
        }
    },
    watch:{
        searchAgenciasValue(value) {
            if (value !== null) {
                /*if(!isNaN(parseInt(value))){
                    value = parseInt(value);
                }
                console.log("searchAgencias", value)*/
                this.searchAgencias(value);
            }
        },
    },
    methods:{
        openDialog() {
            this.dialog = true;
            this.projeto ={
                cliente: null,
                    tipo:{
                    id: null
                },
                agenciaSolicitante:{
                    id: null
                },
                dataSolicitacao: null,
                identificacao: null,
                supervisedBy: null,
            },
            this.getTipos();
            this.getUsuarios();
        },
        closeDialog() {
            this.dialog = false;
            // this.$refs.form1.reset();
            this.$refs.form1.resetValidation();

        },
        getTipos() {
            this.$apollo.query({
                query: gql`query {
                    tipos:projetoTipos{
                        id
                        nome
                    }
                }`
            })
            .then((result) => {
                this.tipos = result.data.tipos.map((tipo) => {
                    tipo.nome = tipo.nome.toUpperCase();
                    return tipo;
                });
            });
        },
        searchAgencias(value) {
            this.isAgenciasLoading = true;
            this.$apollo.query({
                query: AgenciaQueries.findAgencias,
                variables: {arg: value, limit: 10},
            }).then((result) => {
                this.agencias = result.data.agencias.map((agencia) => {
                    agencia.nome = agencia.codigo + ' - ' + agencia.nome;

                    return agencia;
                });
            }).finally(() => {
                this.isAgenciasLoading = false;
            });
        },
        save(){
            if(!this.$refs.form1.validate()){
                return false
            }
            this.$apollo.mutate({
                mutation: gql`mutation ($projeto:ProjetoInput!){
                    projeto:saveProjeto(projeto: $projeto){
                        id
                    }
                }`,
                variables: {
                    projeto: this.parseRequest(this.projeto),
                },
            }).then((result) => {
                this.$router.push({name:"projeto_view", params:{id:result.data.projeto.id}})
            });
        },
        parseRequest(projeto) {
            const projetoParsed = this._.cloneDeep(projeto);

            projetoParsed.cliente = projeto.cliente.toUpperCase();
            projetoParsed.dataSolicitacao = this.convertStringToDate(projetoParsed.dataSolicitacao).getTime();
            projetoParsed.identificacao = projeto.identificacao.replace(/\D/g,'');
            projetoParsed.supervisedBy = {id:projeto.supervisedBy.id};

            return projetoParsed;
        },
        convertStringToDate(dateString) {
            const dateArr = dateString.split('/');

            return new Date(dateArr[1] + '/' + dateArr[0] + '/' + dateArr[2]);
        },
        getUsuarios() {
            this.$apollo.query({
                query: gql`query {
                        usuarios(withInatives: true){
                            id
                            nome
                            sobrenome
                            active
                            email
                            imagem{
                                id
                            }
                        }
                    }`
            }).then((result) => {
                this.usuarios = result.data.usuarios.map((usuario) => {
                    // usuario.nome = usuario.nome + " " + usuario.sobrenome;
                    return usuario;
                });
            });
        },
    }
}
</script>

<style >
    .cliente-text-field input {
        text-transform: uppercase;
    }
</style>