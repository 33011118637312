export class UserData {
    public id: number;
    public nome: string;
    public sobrenome: string;
    public imagem: object;

    constructor(id: number, nome: string, sobrenome: string, imagem: object) {
        this.id = id;
        this.nome = nome;
        this.sobrenome = sobrenome;
        this.imagem = imagem;
    }

    public toLabel() {
        return this.nome + ' ' + this.sobrenome;
    }
}
